import { useEffect, useState } from "react";
import Button from "../generic/Button";
import {
  onGetSchoolReview,
  onUpdateReview,
} from "../../Utils/Servicess/review";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../generic/Spinner";

const ReviewStatusModal = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatcher = useDispatch();

  const school_ = useSelector((state) => state.selectedSchool);
  const [schoolRev, setSchoolRev] = useState([]);
  var authuser = useSelector((state) => state.userStore);
  const navigate = useNavigate();

  useEffect(() => {
    dispatcher({ type: "set-facilities", payload: [] });
  }, [school_]);
  const updatereview = async () => {
    let currtime = new Date();
    const postdatat = {
      schoolname: school_.schoolname,
      itemupdate: {
        ...schoolRev,
        updatedAt: currtime.toDateString(),
        status: "asigned",
        reviewer: `${authuser.firstname} ${authuser.lastname}`,
      },
    };
    setLoading(true);
    try {
      var res = await onUpdateReview(postdatat);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const unassignreview = async () => {
    let currtime = new Date();
    const postdatat = {
      schoolname: school_.schoolname,
      itemupdate: {
        ...schoolRev,
        updatedAt: currtime.toDateString(),
        status: "",
        reviewer: ``,
      },
    };
    setLoading(true);
    try {
      var res = await onUpdateReview(postdatat);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getSelectedschoolReview = async () => {
    setLoading(true);
    var formdata = { schoolname: school_.schoolname };
    var res = await onGetSchoolReview(formdata);
    setLoading(false);
    setSchoolRev(res);
  };

  useEffect(() => {
    getSelectedschoolReview();
  }, []);

  return (
    <div className=" bg-[#0000004f] w-screen h-screen fixed flex justify-center items-center z-[300001] top-0 left-0">
      <Spinner loading={loading} />

      <div className="bg-white rounded p-2 md:p-8 min-w-[40%] md:max-w-[40%] flex justify-center items-center flex-col">
        {schoolRev.status == "asigned" && (
          <p className="p-2 font-light">
            This School review process has been assigned to <br />
            {schoolRev.reviewer}
            <br /> Last update: {schoolRev.updatedAt}
          </p>
        )}
        {schoolRev.status != "asigned" && (
          <p className="p-2 font-light">
            This School has not been assigned to any reviewer, by clicking
            proceed, you will be assigned this school for review and rating
            {schoolRev.reviewer}
          </p>
        )}
        <hr />
        <p>
          {" "}
          Reviewer:
          {schoolRev?.reviewer == "" ? "Not asigned" : schoolRev.reviewer}
        </p>
        <div className="flex gap-4  justify-end items-end self-end">
          <Button
            outlined
            width={200}
            text="Cancel"
            onBtnClick={() => {
              props.setrevmodal(false);
            }}
          />

          {schoolRev?.status == "asigned" && (
            <Button

              text="Un-assign"
               onBtnClick={async () => {
                await unassignreview();
                navigate("/reviewschool");
              }}
              
            />
          )}
          {schoolRev?.reviewer?.includes(
            `${authuser.firstname} ${authuser.lastname}`
          ) ? (
            <Button
              text="Edit"
              onBtnClick={async () => {
                dispatcher({ type: "set-reviewIsViewonly", payload: false });
                await updatereview();
                navigate("/reviewschool");
              }}
            />
          ) : (
            <Button
              text="View"
              onBtnClick={async () => {
                dispatcher({ type: "set-reviewIsViewonly", payload: true });
                navigate("/reviewschool");
              }}
            />
          )}
        </div>
        <div className="p-1">
          <Button
            outlined
            text="View School Dashboard"
            onBtnClick={() => {
              navigate("/dashboard/school");
            }}
          />
        </div>
      </div>
    </div>
  );
};
export { ReviewStatusModal };
